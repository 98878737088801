<template>
  <div>
    <div class="search-box">
      <input class="search" type="input" placeholder="请输入学号或昵称" v-model="keyword">
      <el-button class=search-btn type="primary" round @click="search">搜索</el-button>
    </div>
    <el-table :data="tableData" empty-text="暂无数据" :props="props" :titles="titles" style="width: 100%">
      <el-table-column label="用户头像">
        <template #default="scope">
          <img class="avatar" :src="scope.row.icon" width="50" height="50" alt="">
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip v-for="i in props.length" :key="i" :prop="props[i - 1]" :label="titles[i - 1]" />
      <el-table-column label="申诉状态">
        <template #default="scope">
          <div :class="{'color-gray': scope.row.status == 0, 'color-blue': scope.row.status == 1, 'color-red': scope.row.status == 2}">{{scope.row.status_name}}</div>
          <div v-if="scope.row.status == 2" class="color-red">({{scope.row.rejected_msg}})</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination hide-on-single-page background :page-size="10" layout="prev, pager, next" :total="total" v-model:currentPage="params.page"></el-pagination>
  </div>
</template>

<script>
import {AppealRecord} from '@/api'
export default {
  data() {
    return {
      keyword: '',
      params: {
        page: 1
      },
      props: ['nickname', 'student_id', 'add_time', 'desc'],
      titles: ['昵称', '学号', '申诉时间', '申诉内容'],
      tableData: [],
      total: 1
    }
  },
  watch: {
    keyword(value) {
      if (!value) {
        this.initData()
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      var params = {}
      if (this.keyword) {
        params.keyword = this.keyword
      }
      AppealRecord(params).then(res => {
        console.log(res);
        this.total = res.data.total
        if (res.data.page_data) {
          this.tableData = res.data.page_data
        }
      }).catch(e => {
        this.$message.info(e.msg)
      })
    },
    search() {
      this.initData()
    },
    initData() {
      this.params = {
        page: 1
      }
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .search {
    width: 400px;
    height: 40px;
    padding-left: 15px;
    margin-top: 30px;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    background: #fff;
  }

  .search-btn {
    width: 120px;
    margin-left: 30px;
  }
}

.avatar {
  border-radius: 50%;
}

.color-gray {
  color: #838383;
}

.color-blue {
  color: #4C88FF;
}

.color-red {
  color: #E53A3A;
}
</style>